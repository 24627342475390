import { Project } from '@/app/models/project';
import { useProjectsQuery } from '@/app/services/projectApi';
import {
  useByIdProjectPaymentLimitQuery,
  useCreateProjectPaymentLimitMutation,
} from '@/app/services/projectPaymentLimitApi';
import searchIcon from '@/icons/search.svg';
import { Autocomplete, Box, Button, InputAdornment, InputProps, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CreateProjectExceptionsTable from '../tables/CreateProjectExceptionsTable';

type CreateProjectExceptionProps = {
  onClose: () => void;
  editProject?: string;
};

export default function CreateProjectExceptionDrawer(props: CreateProjectExceptionProps) {
  const { t } = useTranslation(['roles', 'common']);
  const { onClose, editProject } = props;

  const [createProjectException] = useCreateProjectPaymentLimitMutation();

  const [percent, setPercent] = useState('');

  const [costCenter, setCostCenter] = useState('');
  const [payingCostCenter, setPayingCostCenter] = useState('');

  const [searchCostCenter, setSearchCostCenter] = useState('');
  const [searchPayingCostCenter, setSearchPayingCostCenter] = useState('');

  const { data: foundProject } = useProjectsQuery(
    { bringClose: true, searchTerm: editProject || '', page: 0, size: 10 },
    { skip: !editProject },
  );

  const [selectedCostCenter, setSelectedCostCenter] = useState(null as unknown as Project);
  const [selectedPayingCostCenter, setSelectedPayingCostCenter] = useState(null as unknown as Project);

  const { data: costCenters } = useProjectsQuery({
    bringClose: false,
    searchTerm: searchCostCenter,
    page: 0,
    size: 10,
  });
  const { data: payingCostCenters } = useProjectsQuery({
    bringClose: false,
    searchTerm: searchPayingCostCenter,
    page: 0,
    size: 10,
  });

  const { data: exceptions } = useByIdProjectPaymentLimitQuery(selectedCostCenter?.code, {
    skip: !selectedCostCenter,
  });

  const { data: foundProjectExceptions } = useByIdProjectPaymentLimitQuery(foundProject?.content[0].code || '', {
    skip: !foundProject?.content[0].code,
  });

  const handleSubmit = async () => {
    const response = await createProjectException({
      payingProjectCode: editProject || selectedCostCenter.code,
      projectCode: selectedPayingCostCenter.code,
      percent: parseFloat(percent.replaceAll(',', '.')),
    });
    if ('data' in response) {
      toast.success(
        t('exception.case.created.successfully', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <div
      style={{
        width: '480px',
        display: 'flex',
        flexDirection: 'column',
        margin: '24px',
        height: 'calc(100vh - 48px)',
        maxHeight: 'calc(100vh - 48px)',
        flexGrow: 'inherit',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
        <div style={{ color: '#4B4B4B', fontSize: '18px', fontWeight: 'bold', marginBottom: '24px' }}>
          {t('project.exceptions')}
        </div>
        <div>
          <Typography variant="h3">{t('common:payer.project')}</Typography>
          {foundProject?.content ? (
            <Box>
              <Paper sx={{ padding: 3, display: 'flex' }}>
                <Box sx={{ marginRight: 8 }}>
                  <Typography variant="subtitle2">{t('code')}</Typography>
                  <Typography variant="h3">{foundProject?.content[0].code}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">{t('common:name')}</Typography>
                  <Typography variant="h3">{foundProject?.content[0].name}</Typography>
                </Box>
              </Paper>
            </Box>
          ) : (
            <>
              <Typography variant="subtitle1">
                {t('common:choose.the.project.for.which.you.want.to.add.exception.cases')}
              </Typography>
              <Autocomplete
                freeSolo
                filterOptions={(option) => option}
                isOptionEqualToValue={(option, value) => option?.code === value?.code}
                style={{ width: '100%', marginRight: '10px', marginTop: '8px' }}
                size="small"
                defaultValue={selectedCostCenter}
                onChange={(event, newValue) => {
                  setSelectedCostCenter(newValue as Project);
                }}
                inputValue={costCenter}
                onInputChange={(event, newInputValue) => {
                  setCostCenter(newInputValue);
                  if (newInputValue.length >= 1) {
                    setSearchCostCenter(newInputValue);
                  }
                }}
                options={costCenters?.content || []}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : `${option.code} - ${option.name} (${option.branchName})`
                }
                renderOption={(params, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                    {`${option.code} - ${option.name} (${option.branchName})`}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      '& input': {
                        padding: '0px',
                      },
                    }}
                    size="small"
                    style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                    ref={params.InputProps.ref}
                    InputProps={
                      {
                        ...params.inputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <img src={searchIcon} alt={t('common:search.icon') as string} />
                          </InputAdornment>
                        ),
                        style: {
                          paddingRight: '10px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          paddingLeft: '10px',
                          fontSize: '13px',
                        },
                      } as InputProps
                    }
                    autoFocus
                    placeholder={t('common:enter.cost.center.name.or.code') as string}
                  />
                )}
              />
            </>
          )}
        </div>
        <div style={{ width: '100%', border: '1px solid #CCCCCC', marginTop: '32px' }} />
        <div style={{ marginTop: '24px' }}>
          <Typography variant="h3">{t('project.destiny')}</Typography>
          <Typography variant="subtitle1">{t('now.enter.the.project.you.want.to.add.as.a.destination')}</Typography>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <Autocomplete
              freeSolo
              filterOptions={(option) => option}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
              style={{ width: '100%', marginTop: '8px' }}
              size="small"
              defaultValue={selectedPayingCostCenter}
              onChange={(event, newValue) => {
                setSelectedPayingCostCenter(newValue as Project);
              }}
              inputValue={payingCostCenter}
              onInputChange={(event, newInputValue) => {
                setPayingCostCenter(newInputValue);
                if (newInputValue.length >= 1) {
                  setSearchPayingCostCenter(newInputValue);
                }
              }}
              options={payingCostCenters?.content || []}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : `${option.code} - ${option.name} (${option.branchName})`
              }
              renderOption={(params, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                  {`${option.code} - ${option.name} (${option.branchName})`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& input': {
                      padding: '0px',
                    },
                  }}
                  size="small"
                  style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                  ref={params.InputProps.ref}
                  InputProps={
                    {
                      ...params.inputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={searchIcon} alt={t('common:search.icon') as string} />
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: '10px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '10px',
                        fontSize: '13px',
                      },
                    } as InputProps
                  }
                  autoFocus
                  placeholder={t('common:search.by.project.name.or.code') as string}
                />
              )}
            />
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <Typography variant="h3">{t('maximum.percentage.for.target.projects')}</Typography>
          <div style={{ width: '100%', display: 'flex', marginTop: '8px', justifyContent: 'space-between' }}>
            <TextField
              value={percent}
              onChange={(e) => {
                if (/^[0-9.,]*$/.test(e.target.value)) {
                  setPercent(e.target.value);
                }
              }}
              id="outlined-number"
              size="small"
              style={{ width: '100px', height: '32px', fontSize: '13px' }}
              InputLabelProps={{
                shrink: true,
              }}
              lang="pt-br"
              inputProps={{ lang: 'pt-br' }}
            />
            <Button
              variant="tertiary"
              onClick={() => {
                handleSubmit();
              }}
            >
              {t('common:+Add')}
            </Button>
          </div>
        </div>
        <div style={{ marginTop: '24px' }}>
          <CreateProjectExceptionsTable rows={(editProject ? foundProjectExceptions : exceptions) || []} />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button variant="secondary" onClick={() => onClose()}>
          {t('common:close')}
        </Button>
      </div>
    </div>
  );
}
