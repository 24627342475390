import { useSynchronizeMonthlyExpenseMutation } from '@/app/services/monthlyExpenseApi';
import { useCurrentPeriodQuery } from '@/app/services/periodApi';
import { Period } from '@app/models/period';
import { formatDate, formatTime } from '@helpers/date';
import { Button, Card, CardContent, CircularProgress, MenuItem, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import StandardDialog from '@/components/common/StandardDialog';

import Select, { SelectChangeEvent } from '@mui/material/Select';

type PeriodDetailsProps = {
  selectedPeriod?: Period;
  setSelectedPeriod?: Dispatch<SetStateAction<Period | undefined>>;
};

function PeriodDetails(props: PeriodDetailsProps) {
  const { selectedPeriod: inputedSelectedPeriod, setSelectedPeriod: inputedSetSelectedPeriod } = props;
  const { i18n, t } = useTranslation(['events', 'common']);
  let [selectedPeriod, setSelectedPeriod] = useState<Period>();
  const [count, setCount] = useState(0);
  const { data, isSuccess } = useCurrentPeriodQuery(count);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  if (inputedSelectedPeriod) {
    selectedPeriod = inputedSelectedPeriod;
  }
  if (inputedSetSelectedPeriod) {
    setSelectedPeriod = inputedSetSelectedPeriod;
  }

  const [synchronizeMonthlyExpense] = useSynchronizeMonthlyExpenseMutation();

  useEffect(() => {
    setSelectedPeriod(data?.content && data.content[0]);
  }, [data, setSelectedPeriod]);

  useEffect(() => {
    // Implementing the setInterval method
    if (selectedPeriod?.salaryEventsRequested) {
      const id = setInterval(() => {
        setCount(count + 1);
      }, 5000);
      return () => clearInterval(id);
    }
    return () => { };
  }, [selectedPeriod?.salaryEventsRequested, count]);

  const synchronize = async () => {
    await synchronizeMonthlyExpense(selectedPeriod?.date || '');
    setCount(count + 1);
  };

  return (
    <Card sx={{ boxShadow: 3, display: 'flex', minHeight: '72px', alignItems: 'center', padding: '0 8px' }}>
      <StandardDialog
        title={t('synchronize')}
        contentText={`${t('are.you.sure.you.want.to.synchronize.the.accounting.sheet.for.the.current.period')} ${selectedPeriod?.date ? DateTime.fromISO(selectedPeriod.date).setLocale(i18n.language).monthLong : '--'
          }/${selectedPeriod?.date ? DateTime.fromISO(selectedPeriod.date).year : '--'}`}
        open={openSaveDialog}
        onClose={() => {
          setOpenSaveDialog(false);
        }}
        onConfirm={() => {
          setOpenSaveDialog(false);
          synchronize();
        }}
        cancelText={t('common:no')}
        confirmText={t('common:yes')}
      />
      {isSuccess && (
        <CardContent
          sx={{ display: 'flex', maxHeight: '24px', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                margin: '0 25px',
                fontSize: 13,
                fontWeight: 'bold',
                color: '#4B4B4B',
              }}
            >
              {t('common:current.period')}
            </Typography>
            <Select
              value={selectedPeriod || ({} as Period)}
              size="small"
              placeholder={t('Selecione o período') as string}
              sx={{ width: '180px', fontSize: '11px' }}
              onChange={(e: SelectChangeEvent<Period>) => {
                setSelectedPeriod(e.target.value as Period);
              }}
            >
              {data.content.map((period) => (
                <MenuItem
                  sx={{
                    fontSize: '13px',
                    fontWeight: period === selectedPeriod ? 'bold' : 'normal',
                    color: period === selectedPeriod ? '#F97C06' : '#4B4B4B',
                  }}
                  key={period.date}
                  value={period as any}
                >
                  {`${period.date ? DateTime.fromISO(period.date).setLocale(i18n.language).monthLong : '--'}/${period.date ? DateTime.fromISO(period.date).year : '--'
                    }`}
                </MenuItem>
              ))}
            </Select>
          </div>
          {selectedPeriod?.salaryEventsRequested && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  marginRight: '20px',
                  fontSize: '13px',
                }}
              >
                {t('synchronizing.wait.for.a.moment')}
              </div>
              <CircularProgress size={30} color="secondary" />
            </div>
          )}
          {!selectedPeriod?.salaryEventsRequested && (
            <div
              style={{
                fontSize: '13px',
              }}
            >
              {selectedPeriod?.salaryEventsSynchronized
                ? `${t('salary.data.synchronized.at')} ${formatDate(
                  selectedPeriod?.salaryEventsSynchronizedDate,
                  i18n.language,
                )} ${t('common:at')} ${formatTime(selectedPeriod?.salaryEventsSynchronizedDate)}`
                : t('salary.data.not.synchronized.yet')}
            </div>
          )}
          <Button
            disabled={selectedPeriod?.salaryEventsRequested}
            onClick={() => setOpenSaveDialog(true)}
            variant="primary"
            style={{
              marginLeft: '16px',
            }}
          >
            {t('synchronize')}
          </Button>
        </CardContent>
      )}
    </Card>
  );
}

export default PeriodDetails;
