import {
  useDeleteAllProjectPaymentLimitMutation,
  useSearchProjectPaymentLimitQuery
} from '@/app/services/projectPaymentLimitApi';
import LateralDrawer from '@/components/common/LateralDrawer';
import PeriodDetails from '@/components/events/PeriodDetails';
import CreateProjectExceptionDrawer from '@/components/projectExceptions/createProjectExceptionDrawer';
import ProjectExceptionsTable from '@/components/tables/ProjectExceptionsTable';
import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SearchBar } from '../personProductRoles';

function ProjectExceptions() {
  const { t } = useTranslation(['projectExceptions', 'common']);

  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingProject, setEditProject] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [deleteAllPaymentLimit] = useDeleteAllProjectPaymentLimitMutation();

  const handleDeleteAll = async (code: string) => {
    const response = await deleteAllPaymentLimit(code);
    if ('data' in response) {
      toast.success(
        t('exception.case.successfully.deleted', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };
  const { data: exceptions } = useSearchProjectPaymentLimitQuery({
    searchTerm,
    page,
    size: rowsPerPage,
    sortKey: 'name',
    sortDirection: 'ASC',
  });

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '15px',
      }}
    >
      <PeriodDetails />
      <Typography style={{ marginTop: '32px', marginBottom: '0px' }} variant="h1">
        {t('common:exceptions.charges')}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
        <SearchBar
          placeholder={t('common:search.by.name.or.project.code') as string}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <Button variant="tertiary" onClick={() => setShowCreateModal(true)}>{`+ ${t('add.exception.case')}`}</Button>
      </div>

      <ProjectExceptionsTable
        setShowCreateModal={setShowCreateModal}
        setEditProject={setEditProject}
        rows={exceptions?.content || []}
        handleDeleteAll={handleDeleteAll}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={exceptions?.totalElements as number}
      />

      <LateralDrawer
        onClose={() => {
          setEditProject('');
          setShowCreateModal(false);
        }}
        open={showCreateModal}
        setOpen={setShowCreateModal}
        position="right"
        width="100%"
      >
        <CreateProjectExceptionDrawer
          onClose={() => {
            setEditProject('');
            setShowCreateModal(false);
          }}
          editProject={editingProject}
        />
      </LateralDrawer>
    </div>
  );
}
export default ProjectExceptions;
