import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

import { ProjectPaymentLimitByCode } from '@/app/models/projectPaymentLimit';
import {
  useDeleteProjectPaymentLimitMutation,
  useUpdateProjectPaymentLimitMutation,
} from '@/app/services/projectPaymentLimitApi';
import { TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import ActionMenu, { ItemAction } from '../common/ActionMenu';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type CreateProjectExceptionTableProps = {
  rows: Array<ProjectPaymentLimitByCode>;
};

function CreateProjectExceptionsTable(props: CreateProjectExceptionTableProps) {
  const { rows } = props;
  const { t } = useTranslation(['roles', 'common']);

  const [modifications, setModifications] = useState(new Map<number, string>());

  const [updateProjectsExceptions] = useUpdateProjectPaymentLimitMutation();
  const [deleteProjectExceptions] = useDeleteProjectPaymentLimitMutation();

  const handleUpdate = async (id: number) => {
    const newPercent = modifications.get(id);
    if (newPercent) {
      const response = await updateProjectsExceptions({
        id,
        percent: parseFloat(newPercent.replaceAll(',', '.')),
      });
      if ('data' in response) {
        toast.success(
          t('exception.case.created.successfully', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
    }
  };

  const handleDelete = async (id: number) => {
    const response = await deleteProjectExceptions(id);
    if ('data' in response) {
      toast.success(
        t('exception.case.created.successfully', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <TableContainer>
      <Table size="small" aria-label="Exception Case Table">
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('project.destiny')}</HeaderTableCell>
            <HeaderTableCell>{t('percent')}</HeaderTableCell>
            <HeaderTableCell>{t('common:actions')}</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <RowTableCell>{row?.project?.code}</RowTableCell>
              <RowTableCell>
                <TextField
                  value={modifications.has(row.id) ? modifications.get(row.id) : row?.percent}
                  onChange={(e) => {
                    const newMap = new Map<number, string>(
                      Array.from(modifications).map(([key, innerMap]) => [key, innerMap]),
                    );
                    newMap.set(row.id, e.target.value);
                    setModifications(newMap);
                  }}
                  onBlur={() => {
                    handleUpdate(row.id);
                  }}
                  id="outlined-number"
                  size="small"
                  style={{ width: '100px', height: '32px', fontSize: '13px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </RowTableCell>
              <RowTableCell>
                <ActionMenu>
                  <ItemAction
                    text={t('common:delete.rules')}
                    Icon={DeleteIcon}
                    onClick={() => {
                      handleDelete(row.id);
                    }}
                  />
                </ActionMenu>
              </RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                <Typography variant="subtitle2">{t('common:there.are.no.exception.cases.added.yet')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CreateProjectExceptionsTable;
