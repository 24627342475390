import { useEffect, useState } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Drawer, InputAdornment, InputProps, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useThrottle } from 'react-use';

import { ON_CHANGE_DELAY } from '@/app/constants/values';
import { Category } from '@/app/models/category';
import { Expense } from '@/app/models/expense';
import { ExpenseGroup } from '@/app/models/expenseGroup';
import { useExpenseQuery } from '@/app/services/expenseApi';
import ActionMenu, { ItemAction } from '@/components/common/ActionMenu';
import searchIcon from '@/icons/search.svg';
import EditExpense from './EditExpense';

type TranslateFunction = (a: string) => string;
interface SearchBarsProps {
  searchTerm: string;
  setSearchTerm: (arg0: string) => void;
}

export function SearchBar(props: SearchBarsProps) {
  const { searchTerm, setSearchTerm } = props;

  const { t } = useTranslation(['events', 'common']);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '25ch', ml: 0 },
      }}
      noValidate
      autoComplete="off"
    >
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '23px' }}>
        <div style={{ display: 'flex', width: '70%', margin: 'auto 0' }}>
          <TextField
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ minWidth: '360px', height: '32px', fontSize: '13px' }}
            autoFocus
            placeholder={t('search.by.the.code.number') as string}
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={searchIcon} alt={t('common:search.icon') as string} />
                  </InputAdornment>
                ),
                style: {
                  paddingRight: '10px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '10px',
                  fontSize: '13px',
                  height: '100%',
                },
              } as InputProps
            }
          />
        </div>
      </div>
      <div />
    </Box>
  );
}

type CallbackUpdateFunction = (a: string) => void;

const columns = (t: TranslateFunction, openEditDrawer: CallbackUpdateFunction) =>
  [
    {
      field: 'code',
      headerName: t('code'),
      width: 100,
      valueFormatter: ({ value }) => value,
      sortable: false,
    },
    {
      field: 'isShareable',
      headerName: t('shareable'),
      width: 100,
      sortable: false,
      valueFormatter: ({ value }) => (value ? t('common:yes') : t('common:no')),
    },
    {
      field: 'isCredit',
      headerName: t('entry'),
      width: 110,
      sortable: false,
      valueFormatter: ({ value }) => (value ? t('credit') : t('debt')),
    },
    {
      field: 'category',
      headerName: t('category'),
      width: 100,
      sortable: false,
      valueFormatter: ({ value }) => t(value),
    },
    {
      field: 'expenseGroup',
      headerName: t('common:group'),
      width: 200,
      sortable: false,
      flex: 1,
      valueFormatter: ({ value }) => value,
    },
    {
      field: 'id',
      headerName: t('common:actions'),
      sortable: false,
      width: 80,
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu>
          <ItemAction
            text={t('edit.event')}
            Icon={EditOutlinedIcon}
            onClick={() => {
              openEditDrawer(params.id as string);
            }}
          />
        </ActionMenu>
      ),
    },
  ] as GridColDef[];

type RequestGridParams = {
  pageOptions: number[];
  groups: ExpenseGroup[];
  categories: Category[];
};

function RegisteredEvents(params: RequestGridParams) {
  const { pageOptions, groups, categories } = params;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [groupId, setGroupId] = useState<number | null>(null);
  const throttledSearchTerm = useThrottle(searchTerm, ON_CHANGE_DELAY);

  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);
  const [showException, setShowException] = useState(false);
  const [lastTimestamp, setLasTimestamp] = useState(0);

  const {
    data: d,
    isSuccess,
    isLoading,
  } = useExpenseQuery({
    bringOnlyNew: false,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    timestamp: lastTimestamp,
    searchTerm: throttledSearchTerm,
    groupId,
  });
  const { t } = useTranslation(['events', 'common']);

  const localizedTextsMap = {
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} ${t('common:lines.selected')}`
        : `${count.toLocaleString()} ${t('common:line.selected')}`,
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) =>
        `${from} - ${to} ${t('common:of')} ${count}`,
    },
  };

  const filterGroups = [{ id: null, name: t('all.groups') }, ...groups] as Array<ExpenseGroup>;
  const incrementLastTimestamp = () => {
    setLasTimestamp(lastTimestamp + 1);
  };

  const content = ((d && d.content) || []).map(
    ({ code, name, isCredit, isShareable, category, expenseGroup, count }) => ({
      id: code,
      code,
      name,
      isCredit,
      count,
      isShareable,
      category: category?.name || '',
      expenseGroup: expenseGroup?.name || '',
      description: '',
    }),
  );

  const [rowCountState, setRowCountState] = useState(d?.totalElements || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (d?.totalElements !== undefined ? d?.totalElements : prevRowCountState));
  }, [d?.totalElements, setRowCountState]);

  const edit = async (code: string) => {
    setSelectedExpense((d?.content || []).find((c) => c.code === code) || null);
  };
  const myCustomNoRows = () => (
    <div style={{ display: 'flex', justifyContent: 'center', height: '360px', alignItems: 'center' }}>
      <Typography>{t('common:no.results.found')}</Typography>
    </div>
  );
  return (
    <div style={{ height: 'calc(100vh - 420px)', marginTop: '16px' }}>
      <div style={{ marginBottom: '24px', display: 'flex' }}>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Select
          value={filterGroups.find((g) => g.id === groupId)}
          size="small"
          sx={{
            width: '180px',
            fontSize: '13px',
            height: '32px',
            marginTop: '23px',
            marginLeft: '24px',
          }}
          onChange={(event: SelectChangeEvent<ExpenseGroup>) => {
            setGroupId((event.target.value as ExpenseGroup).id);
          }}
        >
          {filterGroups.map((group) => (
            <MenuItem
              sx={{
                fontSize: '13px',
                fontWeight: group?.id === groupId ? 'bold' : 'normal',
                color: group?.id === groupId ? '#F97C06' : '#4B4B4B',
              }}
              key={group.id}
              value={group as any}
            >
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Drawer onBackdropClick={() => setSelectedExpense(null)} open={selectedExpense !== null} anchor="right">
        <EditExpense
          closeDrawer={() => setSelectedExpense(null)}
          groups={groups || []}
          categories={categories || []}
          expense={selectedExpense}
          incrementLastTimestamp={incrementLastTimestamp}
        />
      </Drawer>
      {isSuccess && (
        <DataGrid
          slots={{ noRowsOverlay: myCustomNoRows }}
          disableColumnMenu
          disableColumnFilter
          rowCount={rowCountState}
          sx={{
            border: 'none',
            '& .MuiCheckbox-root.Mui-checked': {
              color: '#ff7b00',
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
          rowHeight={56}
          columnHeaderHeight={40}
          localeText={localizedTextsMap}
          rows={content}
          columns={columns(t, edit)}
          pageSizeOptions={pageOptions}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          paginationMode="server"
          loading={isLoading}
        />
      )}
    </div>
  );
}

export default RegisteredEvents;
