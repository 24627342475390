import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Redirect() {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('login');

  const isControladoria =
    keycloak.hasResourceRole('CONTROLADORIA', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) ||
    process.env.REACT_APP_ENV === 'dev';
  const isAdmin =
    keycloak.hasResourceRole('ADMIN', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || process.env.REACT_APP_ENV === 'dev';

  useEffect(() => {
    if (isControladoria) {
      navigate('/events');
    } else if (isAdmin) {
      navigate('/permission-by-product');
    } else {
      navigate('/');
    }
  }, [isAdmin, isControladoria, navigate]);

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Typography sx={{ marginTop: '42px', marginLeft: '50px' }}>
        {t('your.user.does.not.have.permissions.in.the.system')}
      </Typography>
    </div>
  );
}
export default Redirect;
