import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Box, Button, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Category } from '@/app/models/category';
import { Expense } from '@/app/models/expense';
import { ExpenseGroup } from '@/app/models/expenseGroup';
import { useUpdateExpenseMutation } from '@/app/services/expenseApi';

type AddPermissionsProps = {
  expense?: Expense | null;
  closeDrawer: CallbackFunction;
  incrementLastTimestamp: CallbackFunction;
  groups: ExpenseGroup[];
  categories: Category[];
};

type CallbackFunction = () => void;

export default function EditExpense(props: AddPermissionsProps) {
  const { t } = useTranslation(['events', 'common']);
  const { closeDrawer, incrementLastTimestamp, expense, groups, categories } = props;

  const [editingExpense, setEditingExpense] = useState({ ...expense });

  const updateExpenseProperty = (value: string | boolean | ExpenseGroup | Category, property: string) => {
    const expenseCopy = { ...editingExpense };
    Object.assign(expenseCopy, { [property]: value });

    setEditingExpense(expenseCopy);
  };

  const [updateExpense] = useUpdateExpenseMutation();

  const save = async () => {
    await updateExpense({
      code: editingExpense.code || '',
      name: editingExpense.name,
      isCredit: editingExpense.isCredit,
      isShareable: editingExpense.isShareable,
      expenseGroupId: editingExpense.expenseGroup?.id,
      categoryId: editingExpense.category?.id,
    });
    toast.success(
      t('event.successfully.updated', {
        position: toast.POSITION.TOP_RIGHT,
      }),
    );
    incrementLastTimestamp();
    closeDrawer();
  };

  return (
    <div
      style={{
        width: '480px',
        display: 'flex',
        flexDirection: 'column',
        margin: '24px',
        height: 'calc(100vh - 60px)',
        maxHeight: 'calc(100vh - 60px)',
        flexGrow: 'inherit',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}>
        <div style={{ color: '#4B4B4B', fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>
          {`Evento ${editingExpense?.code}`}
        </div>
        <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
          {t('edit.the.fields.below.or.add.exception.cases')}
        </Typography>
        <div style={{ width: '100%' }}>
          <div style={{ marginTop: '30px', fontSize: '14px', fontWeight: 'bold' }}>{t('shareable')}</div>
          <ToggleButtonGroup
            sx={{ width: '100%', marginTop: '9px' }}
            exclusive
            value={editingExpense.isShareable}
            onChange={(event: React.MouseEvent<HTMLElement>, isShareable: boolean) => {
              updateExpenseProperty(isShareable, 'isShareable');
            }}
          >
            <ToggleButton
              value
              sx={{
                flex: 1,
                height: '22px',
                fontSize: '13px',
                textTransform: 'none',
                fontWeight: `${editingExpense.isShareable ? 'bold' : 'normal'}`,
              }}
            >
              {t('common:yes')}
            </ToggleButton>
            <ToggleButton
              value={false}
              sx={{
                flex: 1,
                height: '22px',
                textTransform: 'none',
                fontSize: '13px',
                fontWeight: `${editingExpense.isShareable ? 'normal' : 'bold'}`,
              }}
            >
              {t('common:no')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div style={{ width: '100%' }}>
          <div style={{ marginTop: '30px', fontSize: '14px', fontWeight: 'bold' }}>{t('entry')}</div>
          <ToggleButtonGroup
            sx={{ width: '100%', marginTop: '9px' }}
            exclusive
            value={editingExpense.isCredit}
            onChange={(event: React.MouseEvent<HTMLElement>, isCredit: boolean) => {
              updateExpenseProperty(isCredit, 'isCredit');
            }}
          >
            <ToggleButton
              value={false}
              sx={{
                flex: 1,
                height: '22px',
                fontSize: '13px',
                textTransform: 'none',
                fontWeight: `${editingExpense.isCredit ? 'normal' : 'bold'}`,
              }}
            >
              {t('debt')}
            </ToggleButton>
            <ToggleButton
              value
              sx={{
                flex: 1,
                height: '22px',
                textTransform: 'none',
                fontSize: '13px',
                fontWeight: `${editingExpense.isCredit ? 'bold' : 'normal'}`,
              }}
            >
              {t('credit')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ marginTop: '30px', fontSize: '14px', fontWeight: 'bold' }}>{t('category')}</div>
          <ToggleButtonGroup
            sx={{ marginTop: '9px', width: '100%' }}
            exclusive
            value={categories.find((c) => c.name === editingExpense.category?.name) || {}}
            onChange={(event: React.MouseEvent<HTMLElement>, category: Category) => {
              updateExpenseProperty(category, 'category');
            }}
          >
            {categories.map((category) => (
              <ToggleButton
                key={category.id}
                value={category}
                sx={{
                  flex: 1,
                  height: '22px',

                  fontSize: '13px',

                  textTransform: 'none',
                  fontWeight: `${editingExpense.category?.name === category?.name ? 'bold' : 'normal'}`,
                }}
              >
                {t(category.name)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>

        <div style={{ width: '100%' }}>
          <div style={{ marginTop: '30px', fontSize: '14px', fontWeight: 'bold' }}>{t('common:group')}</div>
          <Select
            value={groups.find((g) => g.name === editingExpense.expenseGroup?.name) || ''}
            size="small"
            label="Selecione o grupo"
            placeholder="Selecione o grupo"
            sx={{ width: '100%', fontSize: '13px', marginTop: '4px' }}
            onChange={(event: SelectChangeEvent<ExpenseGroup>) => {
              updateExpenseProperty(event.target.value, 'expenseGroup');
            }}
          >
            {groups.map((group) => (
              <MenuItem
                sx={{
                  fontSize: '13px',
                  fontWeight: group.name === editingExpense.expenseGroup?.name ? 'bold' : 'normal',
                  color: group.name === editingExpense.expenseGroup?.name ? '#F97C06' : '#4B4B4B',
                }}
                key={group.name}
                value={group as any}
              >
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div style={{ marginTop: '40px', marginBottom: '40px', width: '100%', border: '1px solid #CCCCCC' }} />

        <Box>
          <Typography variant="subtitle2" color="text.primary" fontWeight="bold" marginBottom={1}>
            {t('common:exception.cases')}
          </Typography>
          <Typography variant="subtitle2" color="text.primary">
            {editingExpense?.count && editingExpense?.count > 0
              ? editingExpense?.count?.toString().padStart(2, '0')
              : '---'}
          </Typography>
        </Box>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button variant="secondary" onClick={() => closeDrawer()}>
          {t('common:cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => save()}
          style={{
            marginLeft: '16px',
          }}
        >
          {t('common:save')}
        </Button>
      </div>
    </div>
  );
}
