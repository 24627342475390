import { RuleClassificationResponse } from '@/app/models/RuleClassification';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActionMenu, { ItemAction } from '../common/ActionMenu';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 'bold',
  borderBottom: 'none',
  padding: theme.spacing(1),
}));

const RowTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 13,
  backgroundColor: 'white',
  borderBottom: 'none',
  padding: theme.spacing(1),
}));

const Separator = styled('div')({
  height: '2px',
  backgroundColor: '#bababa',
  width: '100%',
  margin: 0,
  padding: 0,
});

type RuleClassificationTableGridParams = {
  rows: RuleClassificationResponse[];
  handleDelete: (n: number) => void;
};

function RuleClassificationTable(params: RuleClassificationTableGridParams) {
  const { t } = useTranslation('ruleClassification');

  const { rows, handleDelete } = params;
  return (
    <TableContainer sx={{ marginLeft: 0, padding: 0, width: '100%' }}>
      <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('type.of.sharing')}</HeaderTableCell>
            <HeaderTableCell>{t('rules')}</HeaderTableCell>
            <HeaderTableCell colSpan={5} align="right">
              {t('actions')}
            </HeaderTableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Separator />
      <Table sx={{ tableLayout: 'fixed', width: '100%', fontSize: '16px' }}>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <RowTableCell>{row.projectSubType.name}</RowTableCell>
              <RowTableCell>{row.rule}</RowTableCell>
              <RowTableCell colSpan={5} align="right">
                <ActionMenu>
                  <ItemAction
                    text={t('Deletar')}
                    Icon={DeleteOutlineIcon}
                    onClick={() => {
                      handleDelete(row.id);
                    }}
                  />
                </ActionMenu>
              </RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={7}>
                <Typography variant="subtitle2">{t('no.data.found')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default RuleClassificationTable;
