import { Person } from '@/app/models/person';
import { Product } from '@/app/models/product';
import { usePersonQuery } from '@/app/services/personProductRolesApi';
import { useProductsQuery } from '@/app/services/productApi';

import CreatePersonProductRole from '@/components/personProductRoles/CreatePersonProductRole';

import { Box, Drawer, InputAdornment, InputProps, TextField, Typography } from '@mui/material';

import { ON_CHANGE_DELAY } from '@/app/constants/values';
import searchIcon from '@/icons/search.svg';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThrottle } from 'react-use';

interface SearchBarsProps {
  searchTerm: string;
  setSearchTerm: (arg0: string) => void;
  placeholder?: string;
}

export function SearchBar(props: SearchBarsProps) {
  const { searchTerm, setSearchTerm, placeholder } = props;

  const { t } = useTranslation(['roles', 'common']);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '25ch', ml: 0 },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '23px' }}>
        <div style={{ display: 'flex', width: '70%', margin: 'auto 0' }}>
          <TextField
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ minWidth: '360px', height: '32px', fontSize: '13px' }}
            autoFocus
            placeholder={placeholder || (t('search.by.email.or.name') as string)}
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={searchIcon} alt={t('common:search.icon') as string} />
                  </InputAdornment>
                ),
                style: {
                  paddingRight: '10px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '10px',
                  fontSize: '13px',
                },
              } as InputProps
            }
          />
        </div>
      </div>
      <div />
    </Box>
  );
}

type TranslateFunction = (a: string) => string;
type CallbackFunction = (v: Person | null) => void;

const columns = (t: TranslateFunction, setSelectedUser: CallbackFunction, combined: Array<Person>) => {
  const v: GridColDef[] = [
    {
      field: 'email',
      headerName: t('email'),
      width: 300,
      valueFormatter: ({ value }) => value,
      sortable: false,
    },
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 1,
      valueFormatter: ({ value }) => value,
      sortable: false,
    },
    {
      field: 'id',
      headerName: t('edit.rules'),
      width: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <div
          onClick={() => setSelectedUser(combined.find((c: Person) => c.email === value) || null)}
          onKeyDown={() => setSelectedUser(combined.find((c: Person) => c.email === value) || null)}
          role="button"
          tabIndex={0}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
        >
          <EditIcon sx={{ color: '#4B4B4B', fontSize: 16 }} />
        </div>
      ),
      sortable: false,
    },
  ];
  return v;
};

function PersonProductRoles() {
  const { t } = useTranslation(['roles', 'common']);
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedUser, setSelectedUser] = useState<Person | null>(null);

  const [searchTerm, setSearchTerm] = useState('');
  const throttledSearchTerm = useThrottle(searchTerm, ON_CHANGE_DELAY);
  const [lastTimestamp, setLasTimestamp] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { data: products } = useProductsQuery();
  useEffect(() => {
    setProduct(products?.find((p) => p.name === process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || null);
  }, [products]);

  const currentResult = usePersonQuery({
    searchTerm: throttledSearchTerm,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    timestamp: lastTimestamp,
  });

  const [rowCountState, setRowCountState] = useState(currentResult?.data?.totalElements || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      currentResult?.data?.totalElements !== undefined ? currentResult?.data?.totalElements : prevRowCountState,
    );
  }, [currentResult?.data?.totalElements, setRowCountState]);

  const incrementLastTimestamp = () => {
    setLasTimestamp(lastTimestamp + 1);
  };

  const localizedTextsMap = {
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} ${t('common:lines.selected')}`
        : `${count.toLocaleString()} ${t('common:line.selected')}`,
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) =>
        `${from} - ${to} ${t('common:of')} ${count}`,
    },
  };

  const myCustomNoRows = () => (
    <div style={{ display: 'flex', justifyContent: 'center', height: '360px', alignItems: 'center' }}>
      <Typography>{t('common:no.results.found')}</Typography>
    </div>
  );

  return (
    <div style={{ width: '100%' }}>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Drawer onBackdropClick={() => setSelectedUser(null)} open={selectedUser !== null} anchor="right">
        <CreatePersonProductRole
          closeDrawer={() => setSelectedUser(null)}
          person={selectedUser}
          incrementLastTimestamp={incrementLastTimestamp}
          product={product}
        />
      </Drawer>
      <div style={{ height: '500px', marginTop: '16px' }}>
        {currentResult?.data?.content && (
          <DataGrid
            slots={{ noRowsOverlay: myCustomNoRows }}
            disableColumnMenu
            disableColumnFilter
            rowCount={rowCountState}
            sx={{
              border: 'none',
              '& .MuiCheckbox-root.Mui-checked': {
                color: '#ff7b00',
              },
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'none',
              },
              '& .MuiDataGrid-row': { cursor: 'pointer' },
            }}
            rowHeight={40}
            columnHeaderHeight={40}
            localeText={localizedTextsMap}
            rows={currentResult?.data?.content.map(({ name, email }) => ({
              name,
              email,
              id: email,
            }))}
            columns={columns(t, setSelectedUser, currentResult?.data?.content)}
            pageSizeOptions={[5]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
          />
        )}
      </div>
    </div>
  );
}

export default PersonProductRoles;
