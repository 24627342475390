import { useAllPersonExpenseQuery } from '@/app/services/monthlyExpenseApi';
import PeriodDetails from '@/components/events/PeriodDetails';

import { Period } from '@/app/models/period';
import EmployeeEventsTable from '@/components/tables/EmployeeEventsTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from '../personProductRoles';

function EmployeeEvents() {
  const { t } = useTranslation(['employeeEvents', 'common']);
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedPeriod, setSelectedPeriod] = useState<Period>();

  const { data } = useAllPersonExpenseQuery({
    period: selectedPeriod?.date as string,
    badge: searchTerm,
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
      <PeriodDetails selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />

      <div style={{ marginTop: '32px', fontSize: '18px', fontWeight: 'bold' }}>{t('allocation.result')}</div>
      <div style={{ color: '#AAAAAA' }}>{t('list.allocation.events.specific.employee.chosen.period')}</div>
      <div style={{ marginBottom: '24px' }} />

      <div style={{ marginBottom: '24px', display: 'flex' }}>
        <SearchBar
          placeholder={t('common:employee.registration') as string}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
      <EmployeeEventsTable rows={data || []} />
    </div>
  );
}
export default EmployeeEvents;
