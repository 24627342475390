import { RuleTypeConfig } from '@/app/models/ruleTypeConfig';
import { useDeleteRuleTypeConfigMutation, useGetRuleTypeQuery } from '@/app/services/ruleTypeConfig';
import LateralDrawer from '@/components/common/LateralDrawer';
import PeriodDetails from '@/components/events/PeriodDetails';
import CreateReversionDrawer from '@/components/reversions/createReversionDrawer';
import ReversionTable from '@/components/tables/ReversionTable';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SearchBar } from '../personProductRoles';

function Reversions() {
  const { t } = useTranslation(['reversion', 'common']);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [deleteRuleTypeConfig] = useDeleteRuleTypeConfigMutation();
  const [editingReversion, setEditingReversion] = useState(null as unknown as RuleTypeConfig);

  const { data: reversions } = useGetRuleTypeQuery({ searchTerm, page, size });

  const handleDelete = (id: number) => {
    const response = deleteRuleTypeConfig(id);
    if ('data' in response) {
      toast.success(
        t('Caso de Exceção removido com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '15px',
      }}
    >
      <PeriodDetails />
      <Typography style={{ marginTop: '32px', marginBottom: '0px' }} variant="h1">
        {t('rollback.rules')}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
        <SearchBar
          placeholder={t('common:search.by.name.or.project.code') as string}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <Button onClick={() => setShowCreateModal(true)} variant="tertiary">{`+ ${t('add.rollback.rule')}`}</Button>
      </div>
      <ReversionTable
        onEdit={(reversion) => {
          setEditingReversion(reversion);
          setShowCreateModal(true);
        }}
        handleDelete={handleDelete}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        count={reversions?.totalElements || 0}
        rows={reversions?.content || []}
      />
      <LateralDrawer
        onClose={() => {
          setShowCreateModal(false);
          setEditingReversion(null as unknown as RuleTypeConfig);
        }}
        open={showCreateModal}
        setOpen={setShowCreateModal}
        position="right"
      >
        <CreateReversionDrawer
          editingReversion={editingReversion}
          onClose={() => {
            setShowCreateModal(false);
            setEditingReversion(null as unknown as RuleTypeConfig);
          }}
        />
      </LateralDrawer>
    </div>
  );
}
export default Reversions;
