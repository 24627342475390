import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

import { ExpenseExceptionComplete } from '@/app/models/expenseException';
import { Typography } from '@mui/material';
import ActionMenu, { ItemAction } from '../common/ActionMenu';

const HeaderTableCell = styled(TableCell)(() => ({
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type CreateExceptionTableProps = {
  rows: Array<ExpenseExceptionComplete>;
  onDelete: (id: number) => void;
};

function CreateExceptionsTable(props: CreateExceptionTableProps) {
  const { rows, onDelete } = props;
  const { t } = useTranslation(['exceptions', 'common']);
  return (
    <TableContainer>
      <Table size="small" aria-label="Exception Case Table">
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('Evento')}</HeaderTableCell>
            <HeaderTableCell>{t('common:group')}</HeaderTableCell>
            <HeaderTableCell>{t('common:payer.project')}</HeaderTableCell>
            <HeaderTableCell>{t('common:actions')}</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <RowTableCell>{row.expense.code}</RowTableCell>
              <RowTableCell>{row.expense.category?.name}</RowTableCell>
              <RowTableCell>{`${row.destini.code} - ${row.destini.name}`}</RowTableCell>
              <RowTableCell>
                <ActionMenu>
                  <ItemAction
                    text={t('common:delete.rules')}
                    Icon={DeleteIcon}
                    onClick={() => {
                      onDelete(row.id);
                    }}
                  />
                </ActionMenu>
              </RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                <Typography variant="subtitle2">{t('common:there.are.no.exception.cases.added.yet')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CreateExceptionsTable;
