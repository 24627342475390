import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

import { Expense } from '@/app/models/expense';
import { Typography } from '@mui/material';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type BatchExceptionsTableProps = {
  rows: Array<Expense>;
};

function BatchExceptionsTable(props: BatchExceptionsTableProps) {
  const { rows } = props;
  const { t } = useTranslation(['exceptions', 'common']);
  return (
    <TableContainer>
      <Table size="small" aria-label="Exception Case Table">
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('code')}</HeaderTableCell>
            <HeaderTableCell>{t('shareable')}</HeaderTableCell>
            <HeaderTableCell>{t('entry')}</HeaderTableCell>
            <HeaderTableCell>{t('category')}</HeaderTableCell>
            <HeaderTableCell>{t('common:group')}</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <RowTableCell>{row.code}</RowTableCell>
              <RowTableCell>{row.isShareable ? t('common:yes') : t('common:no')}</RowTableCell>
              <RowTableCell>{row.isCredit ? t('credit') : t('debt')}</RowTableCell>
              <RowTableCell>{row.category?.name}</RowTableCell>
              <RowTableCell>{row.expenseGroup?.name}</RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                <Typography variant="subtitle2">{t('no.events.added')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BatchExceptionsTable;
