import { RuleTypeConfig } from '@/app/models/ruleTypeConfig';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TablePagination, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import ActionMenu, { ItemAction } from '../common/ActionMenu';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type ReversionTableProps = {
  rows: Array<RuleTypeConfig>;
  count: number;
  rowsPerPage: number;
  setRowsPerPage: (n: number) => void;
  page: number;
  setPage: (n: number) => void;
  handleDelete: (n: number) => void;
  onEdit: (r: RuleTypeConfig) => void;
};

function ReversionTable(props: ReversionTableProps) {
  const { rows, count, rowsPerPage, setRowsPerPage, page, setPage, handleDelete, onEdit } = props;
  const { t } = useTranslation(['reversion', 'common']);

  return (
    <TableContainer>
      <Table size="small" aria-label="Exception Case Table">
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('Cluster')}</HeaderTableCell>
            <HeaderTableCell>{t('branch')}</HeaderTableCell>
            <HeaderTableCell>{t('cc.classification')}</HeaderTableCell>
            <HeaderTableCell>{t('common:sharing.type')}</HeaderTableCell>
            <HeaderTableCell>{t('common:payer.project')}</HeaderTableCell>
            <HeaderTableCell>{t('common:actions')}</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <RowTableCell>{row.cluster.name}</RowTableCell>
              <RowTableCell>{row.branch.name}</RowTableCell>
              <RowTableCell>{row.projectType.name}</RowTableCell>
              <RowTableCell>{row.projectSubType?.name || '---'}</RowTableCell>
              <RowTableCell>{row.project.name}</RowTableCell>
              <RowTableCell>
                <ActionMenu>
                  <ItemAction
                    text={t('edit.rule')}
                    Icon={EditOutlinedIcon}
                    onClick={() => {
                      onEdit(row);
                    }}
                  />
                  <ItemAction
                    text={t('delete.rule')}
                    Icon={DeleteIcon}
                    onClick={() => {
                      handleDelete(row.id);
                    }}
                  />
                </ActionMenu>
              </RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                <Typography variant="subtitle2">{t('There.are.no.rollback.rules.added')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        sx={{ width: '100%' }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => setPage(p)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
        labelDisplayedRows={({ from, to, count: tcount }) => `${from}-${to} ${t('common:of')} ${tcount}`}
        labelRowsPerPage={t('common:items.per.page')}
      />
    </TableContainer>
  );
}

export default ReversionTable;
